import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import lhome1 from "./assets/images/icons/logos/lhome1.png";
import axios from "./config/axiosConfig";
import Services from "./components/home/Services";

const Home = () => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/');
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {/* Hero Section */}
      <div className="flex md:flex-row lg:h-80dvh">
        <div
          className="absolute inset-0 opacity-30 blur-2xl"
          style={{ pointerEvents: "none", zIndex: -1 }}
        ></div>
        <div
          className="absolute  w-40 lg:w-96 h-96 bg-gradient-to-r from-yellow-500 via-red-500 to-purple-500 rounded-full opacity-10 blur-3xl"
          style={{ pointerEvents: "none", zIndex: -1 }}
        ></div>
        <div
          className="absolute top-1/2 right-1/2 w-10 lg:w-96 h-96 bg-gradient-to-r from-green-500 via-blue-500 to-teal-500 rounded-full opacity-20 blur-3xl"
          style={{ pointerEvents: "none", zIndex: -1 }}
        ></div>
        <div
          data-aos="fade-right"
          className="flex-1 flex flex-col w-full justify-center items-center   "
        >
          <div className="text-3xl ">
            <h2 className="text-black font-inter text-sm md:text-lg lg:text-xl xl:text-2xl font-light leading-normal md:tracking-wider tracking-wider uppercase mx-4 md:mx-5">
              Hi!, I'm Arjun Singh
            </h2>
            <h2 className="text-black font-inter text-sm md:text-lg lg:text-xl font-light leading-normal md:tracking-wider tracking-wider uppercase mx-4 md:mx-5 xl:text-2xl ">
              Full Stack
            </h2>
            <h1 className="text-lg text-black font-inter text-2xl md:text-3xl font-medium leading-normal md:tracking-widest tracking-widest uppercase mx-4 md:mx-5 xl:text-4xl ">
              Developer
            </h1>
          </div>
          <div className="p-4">
            <Link to="/contactus">
              <button
                type="button"
                className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 font-medium rounded-full text-sm px-5 py-2.5 mb-2"
              >
                Contact Us
              </button>
            </Link>
          </div>
        </div>
        <div data-aos="zoom-in" className="hidden flex-1 flex w-full  justify-center   pt-20 lg:flex ">
          <img
            className="h-auto max-w-full max-h-[200px] sm:max-h-[300px] md:max-h-[400px] object-contain"
            src={lhome1}
            alt="Logo"
          />
        </div>
      </div>
      <Services />
    </>
  );
};

export default Home;
