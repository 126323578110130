import React, { useState } from "react";
import Cimage from "./assets/images/icons/logos/Cimage.png";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import { HiMail } from "react-icons/hi";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
const MyForm = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-in-out', // Smooth animation
    });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const response = await fetch(
        "https://portfoliobackendarjunsingh27.onrender.com/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        showAlert("Form data sent successfully");
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        console.error("Failed to send form data");
        showAlert("Failed to send form data. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      showAlert("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="flex flex-col lg:flex-row  justify-between   gap-4 lg:m-20 m-5 ">
     
      <div data-aos="fade-right" className="p-6 max-w-md bg-white rounded-lg shadow-md w-full lg:w-1/2 m-auto ">
        {alertMessage && (
          <div className="alert alert-success mb-4 p-3 bg-green-100 border border-green-200 text-green-800 rounded">
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-green-500 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span>{alertMessage}</span>
            </div>
          </div>
        )}
        <form  onSubmit={handleSubmit} className="space-y-4 ">
          <label className="block">
            <span className="text-gray-700">Name</span>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="input input-bordered w-full mt-1"
              placeholder="Enter your name"
              required
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Email</span>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="input input-bordered w-full mt-1"
              placeholder="Enter your email"
              required
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Message</span>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="textarea textarea-bordered w-full mt-1"
              placeholder="Enter your message"
              rows="4"
              required
            />
          </label>
          <button
            type="submit"
            className={`btn btn-primary w-full bg-blue-600 ${loading ? "cursor-not-allowed" : ""}`}
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center justify-center">
                <svg
                  className="animate-spin h-5 w-5 mr-3 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Processing...
              </span>
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>

      <div className="w-full lg:w-2/3 flex flex-col lg:grid lg:grid-cols-1 gap-6 p-10">
      {/* Image Section */}

      {/* Details Section */}
      <div data-aos="fade-down" className="flex flex-col justify-center">
         <h4 className="font-extrabold text-gray-500 mt-0 mb-2 i  text-center about_heading text-3xl font-bold ">
         Get in Touch
            </h4>
        <p className="text-lg leading-relaxed text-slate-700 text-center">
        Ready to take your business to the next level? I'm here to help you build an impressive online presence. Whether you're looking to create a stunning website or enhance your digital footprint, I'm just an email away. Reach out today, and I’ll get back to you within 48 hours.
        </p>
         
      </div>

      {/* <div className="flex justify-center items-center z--1">
        <img className="rounded-lg   w-full h-40 object-contain" src={Cimage} alt="Contact Illustration" />
      </div> */}
     
      {/* Email Section */}
      <div data-aos="fade-up" className="relative col-span-2 text-center mt-6 lg:mt-0">
          <a href="mailto:arjun.dev25@gmail.com" className="flex items-center justify-center space-x-2" type="mail">
            <HiMail className="text-2xl text-gray-500" />
            <p className="text-xs font-medium">arjun.dev25@gmail.com</p>
          </a>
        </div>
    </div>
    <div   className="flex lg:flex-col sm:flex-row justify-center ">
    <a
        className="m-2 transform transition-transform duration-300 hover:scale-110"
        href="https://github.com/arjunsingh27"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GitHubIcon style={{ color: "black", fontSize: 40 }} />
      </a>
      <a
        className="m-2 transform transition-transform duration-300 hover:scale-110"
        href="https://www.linkedin.com/in/arjun-singh-27-/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedInIcon style={{ color: "#007bb5", fontSize: 40 }} />
      </a>
      <a
        className="m-2 transform transition-transform duration-300 hover:scale-110"
        href="https://arjun.ltd"
        target="_blank"
        rel="noopener noreferrer"
      >
        <YouTubeIcon style={{ color: "red", fontSize: 40 }} />
      </a>
      <a
        className="m-2 transform transition-transform duration-300 hover:scale-110"
        href="https://twitter.com/arjun_jaat8_"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TwitterIcon style={{ color: "#1DA1F2", fontSize: 40 }} />
      </a>
          </div>
    </div>
  );
};

export default MyForm;
