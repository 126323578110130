// config/axiosConfig.js
import axios from 'axios';

// Define the base URLs for local and live environments
const localURL = 'http://localhost:4000/';
const liveURL = 'https://portfoliobackendarjunsingh27.onrender.com/';

// Choose the appropriate base URL
const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? localURL : liveURL,
});

export default instance;
