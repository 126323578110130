import React from 'react';
import { HiExternalLink } from 'react-icons/hi'; // Import icons from react-icons
import GitHubIcon from "@mui/icons-material/GitHub";

const Build = ({ projecttitle, detail, image, datecreated, liveUrl, githubUrl }) => {
  return (
    <a 
      className="relative block transform transition-transform duration-300 hover:scale-105 p-2" href={liveUrl} target="_blank" rel="noopener noreferrer">
      <div
       data-aos="fade-up"
        className="overflow-hidden h-30 lg:h-40 p-6  bg-white/30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg hover:z-10 relative"
       
      >
        <h3 className="text-lg lg:text-xl font-semibold mb-4">{projecttitle}</h3>
        <p className="text-gray-600">{detail.slice(0,90)}......</p>
       
      </div>
      <div className="backdrop-blur-sm absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
        <a href={liveUrl} target="_blank" rel="noopener noreferrer" className="text-white bg-green-500 rounded-full p-2 m-2">
          <HiExternalLink size={24} />
        </a>
        <a
        className=""
        href={githubUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <GitHubIcon style={{ color: "black", fontSize: 40 }} />
      </a>
      </div>
    </a>
  );
}

export default Build;
