import React, { useEffect } from 'react';
import vlogs from './bd';
import { useParams } from 'react-router-dom';
import CodeBlock from './CodeBlock'; // Import CodeBlock component
import AOS from 'aos';
import 'aos/dist/aos.css';

const Blog = () => {
  // Get the blog ID from URL parameters
  const { id } = useParams();
  
  // Find the specific vlog based on the ID
  const vlog = vlogs.find((blog) => blog.id === parseInt(id));

  // Initialize AOS (Animate On Scroll) for animations
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
    });
  }, []);

  // If vlog not found, display an error message
  if (!vlog) {
    return <div className="text-center text-red-500">Blog not found</div>;
  }

  // Format the blog's creation date
  const formattedDate = new Date(vlog.datecreated).toLocaleDateString('en-US', {
    month: 'long', // Full month name
    year: 'numeric' // 4-digit year
  });

  return (
    <div data-aos="fade-up" className="mt-20 mb-20 m-2 ">
      <div className="relative w-12/12 lg:w-9/12 mx-auto p-6 bg-white/30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg hover:z-10">
        {/* Blog thumbnail */}
        <img
          src={vlog.thumbnail}
          alt={vlog.title}
          className="w-full max-h-40 object-cover mb-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
          data-aos-delay="400"
        />
        
        {/* Gradient overlay for background effect */}
        <div className="absolute inset-0 bg-gradient-to-br from-transparent via-transparent to-gray-200 opacity-30 rounded-lg z-0"></div>
        
        <div className="relative z-10">
          {/* Blog title */}
          <div className="mb-4 text-xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-3xl text-black">
            {vlog.title}
          </div>
          
          {/* Blog date */}
          <p className="text-sm text-gray-500 mb-6">
            {formattedDate}
          </p>
          
          {/* Blog introduction */}
          <p className="mb-6 text-lg">
            {vlog.introduction}
          </p>
          
          {/* Section one */}
          {vlog.sectionone && (
            <section className="mb-12">
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                {vlog.sectiononeheading}
              </h2>
              <p>{vlog.sectionone}</p>
              <CodeBlock codeheading={vlog.sonecodeheading} code={vlog.sonecode} />
            </section>
          )}

          {/* Section two */}
          {vlog.sectiontwo && (
            <section className="mb-12">
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                {vlog.sectiontwoheading}
              </h2>
              <p>{vlog.sectiontwo}</p>
              <CodeBlock codeheading={vlog.stwocodeheading} code={vlog.stwocode} />
            </section>
          )}

          {/* Section three */}
          {vlog.sectionthree && (
            <section className="mb-12 flex flex-col lg:flex-row">
              <div className="flex-1 mr-4">
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  {vlog.sectionthreeheading}
                </h2>
                <p className="custom-list" dangerouslySetInnerHTML={{ __html: vlog.sectionthree }}></p>
                <CodeBlock codeheading={vlog.sthreecodeheading} code={vlog.sthreecode} />
              </div>
              <div className="flex-1 ">
                <img src={vlog.sthreeimage} alt="Image description" className="w-full h-full object-cover rounded-lg" />
              </div>
            </section>
          )}

          {/* Section four */}
          {vlog.sectionfour && (
            <section className="mb-12">
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                {vlog.sectionfourheading}
              </h2>
              <p>{vlog.sectionfour}</p>
              <CodeBlock codeheading={vlog.sfourcodeheading} code={vlog.sfourcode} />
            </section>
          )}

          {/* Section five */}
          {vlog.sectionfive && (
            <section className="mb-12">
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                {vlog.sectionfiveheading}
              </h2>
              <p>{vlog.sectionfive}</p>
              <CodeBlock codeheading={vlog.sfivecodeheading} code={vlog.sfivecode} />
            </section>
          )}

          {/* Section six */}
          {vlog.sectionsix && (
            <section className="mb-12">
              <h2 className="text-2xl font-semibold text-gray-800 mb-2 ">
                {vlog.sectionsixheading}
              </h2>
              <p>{vlog.sectionsix}</p>
              <CodeBlock codeheading={vlog.ssixcodeheading} code={vlog.ssixcode} />
            </section>
          )}

          {/* Section seven */}
          {vlog.sectionseven && (
            <section className="mb-12 flex flex-col lg:flex-row">
              <div className="flex-1">
                <img src={vlog.ssevenimage} alt="Image description" className="w-full h-full object-cover rounded-lg" />
              </div>
              <div className="flex-1 mr-4 mt-4">
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  {vlog.sectionsevenheading}
                </h2>
                <p className="custom-list" dangerouslySetInnerHTML={{ __html: vlog.sectionseven }}></p>
                <CodeBlock codeheading={vlog.ssevencodeheading} code={vlog.ssevencode} />
              </div>
            </section>
          )}

          {/* Summary */}
          {vlog.summary && (
            <section className="mb-12">
              <h3 className="text-xl font-semibold text-gray-800 mb-6">
                {vlog.summaryheading || 'Summary'}
              </h3>
              <p dangerouslySetInnerHTML={{ __html: vlog.summary }}></p>
              <CodeBlock codeheading={vlog.summarycodeheading} code={vlog.summarycode} />
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blog;
