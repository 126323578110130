import React, { useEffect, useState } from "react";
import Allprojectcard from "./components/projects/allprojectcard.jsx";
import SkillComponent from "./components/projects/skills.jsx";
import AOS from "aos";
import localprojects from "./components/projects/localprojects.js"; // Import local projects
import Build from "./components/projects/Build.jsx";
import axios from "./config/axiosConfig";

const Project = () => {
  const [projectsDetail, setProjectsDetail] = useState(localprojects); // Initialize with local projects
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      easing: "ease-in-out", // Animation easing
      once: true, // Whether animation should happen only once
    });

    async function fetchProjectsDetail() {
      setLoading(false);
      try {
        // Fetch data from the server using Axios
        const response = await axios.get('/projects'); // Base URL is handled by axiosConfig
      
        const dbjson = response.data;
        console.log("Fetched Projects:", dbjson);
      
        // Sort projects by date created, most recent first
        const sortedProjects = dbjson.sort(
          (a, b) => new Date(b.datecreated) - new Date(a.datecreated)
        );
      
        // Update the state with the sorted projects data
        setProjectsDetail(sortedProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    }

    fetchProjectsDetail();
  }, []);

  return (
    <div className="pl-5 pr-5 md:pl-20 md:pr-20 mt-10">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="loader border-t-4 border-blue-500 w-16 h-16 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="relative py-12">
          {/* Background effects */}
          <div className="absolute inset-0 opacity-30 blur-2xl"></div>
          <div
            className="absolute top-1/2 w-40 right-1/2 lg:w-96 h-96 bg-gradient-to-r from-yellow-500 via-red-500 to-purple-500 rounded-full opacity-20 blur-3xl"
            style={{ pointerEvents: "none", zIndex: -1 }}
          ></div>
          <div
            className="absolute top-1/1 right-1/4 w-10 lg:w-96 h-96 bg-gradient-to-r from-green-500 via-blue-500 to-teal-500 rounded-full opacity-20 blur-3xl"
            style={{ pointerEvents: "none", zIndex: -1 }}
          ></div>

          <div className="relative max-w-7xl mx-auto  sm:px-6 lg:px-8">
            <h2
              className="text-3xl font-extrabold text-gray-500 text-center mb-8"
              data-aos="fade-up"
            >
           Builds
            </h2>
            <div    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {projectsDetail.map((build) => (
                <Build  
                  key={build.id}
                  projecttitle={build.projecttitle}
                  detail={build.projectdescription}
                  image={build.projectimage}
                  datecreated={build.datecreated}
                  liveUrl={build.url}
                  githubUrl={build.githubUrl}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="h-dvh">
        <SkillComponent />
      </div>
    </div>
  );
};

export default Project;
