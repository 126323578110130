import React, { useEffect, useState } from "react";
import BlogsCard from "./components/blogs/BlogsCard";
import vlogs from "./components/blogs/bd";
import AOS from "aos";
import "aos/dist/aos.css";

const Blogs = () => {
  const [blogs, setBlogs] = useState(vlogs);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <h2
        className="text-3xl font-extrabold text-gray-500 text-center mt-20 mb-10"
        data-aos="fade-up"
      >
        Blogs
      </h2>

      <div className="blogs_container">
        {blogs.map((blog) => (
          <BlogsCard key={blog.id} {...blog} />
        ))}
      </div>
    </div>
  );
};

export default Blogs;
