import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "./config/axiosConfig";
import Pupload from "./assets/images/icons/logos/Pupload.png";

const AdminPage = () => {
  const [code, setCode] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const handleCodeSubmit = (e) => {
    e.preventDefault();
    const correctCode = "don"; // Replace with your actual code

    if (code === correctCode) {
      setIsAuthenticated(true);
      setError(null);
    } else {
      setError("Incorrect code, redirecting to the home page...");
      setTimeout(() => history.push("/"), 2000); // Redirect after 2 seconds
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="p-6 rounded-lg overflow-hidden bg-white border">
          <h4 className="text-3xl font-bold text-blue-800 mb-10 bg-gradient-to-r from-cyan-900 to-blue-300 bg-clip-text text-transparent text-center">
            Admin Access
          </h4>
          {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
          <form onSubmit={handleCodeSubmit} className="space-y-6">
            <div className="form-control">
              <label htmlFor="adminCode" className="label">
                <span className="label-text">Enter Admin Code</span>
              </label>
              <input
                type="password"
                id="adminCode"
                name="adminCode"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
                className="input input-bordered w-full"
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary w-full bg-blue-500"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }

  return <AddProjectForm />;
};

const AddProjectForm = () => {
  const [formData, setFormData] = useState({
    projecttitle: "",
    projectdescription: "",
    projectimage: "",
    url: "",
    githubUrl: "",
    datecreated: "",
    passward: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        "/projects",
        formData
      );

      console.log("Project added:", response.data);

      setFormData({
        projecttitle: "",
        projectdescription: "",
        projectimage: "",
        url: "",
        githubUrl: "",
        datecreated: "",
        passward: "",
      });
    } catch (err) {
      setError("Failed to add project");
      console.error("Error adding project:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-around min-h-screen">
      <div className="relative p-6 rounded-lg overflow-hidden bg-white border">
        <h4 className="text-3xl font-bold text-blue-800 mb-10 bg-gradient-to-r from-cyan-900 to-blue-300 bg-clip-text text-transparent text-center">
          Add New Build
        </h4>
        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="form-control">
              <label htmlFor="projecttitle" className="label">
                <span className="label-text">Project Title</span>
              </label>
              <input
                type="text"
                id="projecttitle"
                name="projecttitle"
                value={formData.projecttitle}
                onChange={handleChange}
                required
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control">
              <label htmlFor="projectdescription" className="label">
                <span className="label-text">Project Description</span>
              </label>
              <textarea
                id="projectdescription"
                name="projectdescription"
                value={formData.projectdescription}
                onChange={handleChange}
                required
                className="textarea textarea-bordered w-full"
              ></textarea>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="form-control">
              <label htmlFor="projectimage" className="label">
                <span className="label-text">Project Image URL</span>
              </label>
              <input
                type="text"
                id="projectimage"
                name="projectimage"
                value={formData.projectimage}
                onChange={handleChange}
                required
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control">
              <label htmlFor="url" className="label">
                <span className="label-text">Project URL</span>
              </label>
              <input
                type="text"
                id="url"
                name="url"
                value={formData.url}
                onChange={handleChange}
                required
                className="input input-bordered w-full"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="form-control">
              <label htmlFor="githubUrl" className="label">
                <span className="label-text">GitHub URL</span>
              </label>
              <input
                type="text"
                id="githubUrl"
                name="githubUrl"
                value={formData.githubUrl}
                onChange={handleChange}
                required
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control">
              <label htmlFor="datecreated" className="label">
                <span className="label-text">Date Created</span>
              </label>
              <input
                type="date"
                id="datecreated"
                name="datecreated"
                value={formData.datecreated}
                onChange={handleChange}
                required
                className="input input-bordered w-full"
              />
            </div>
          </div>
          <div className="form-control">
            <label htmlFor="passward" className="label">
              <span className="label-text">Password</span>
            </label>
            <input
              type="password"
              id="passward"
              name="passward"
              value={formData.passward}
              onChange={handleChange}
              required
              className="input input-bordered w-full"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="btn btn-primary w-full bg-blue-500"
          >
            {loading ? "Adding..." : "Add Project"}
          </button>
        </form>
      </div>
      <div className="hidden lg:block">
        <img
          src={Pupload}
          alt="Project"
          className="object-cover w-full h-full ml-10"
        />
      </div>
    </div>
  );
};

export default AdminPage;
