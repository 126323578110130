import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const UL = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;

  li {
    padding: 18px 10px;
    margin-right: 50px;
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  li::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 10px; /* Adjust to match padding if necessary */
    left: 0;
    background-color: white;
    transition: width 0.3s ease;
    border-radius: 10px;
  }

  li:hover::after {
    width: 100%;
    transition: width 0.3s ease;
  }

  li:hover {
    text-decoration: none; /* Remove default underline */
    text-underline-offset: 4px; /* Space between text and underline */
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background: linear-gradient(180deg, #35DBBD 0%, #184C9A 100%);
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 200px;
    transition: transform 0.3s ease-in-out;
    padding-top: 3.5rem;
    z-index: 19;

    li {
      margin: auto;
      margin-top: 0px;
      margin-bottom: 0px;
      width: 100px;
    }
  }
`;

const RightNav = ({ open }) => {
  return (
    <UL open={open}>
      <Link to="/home" style={{ textDecoration: 'none' }}>
        <li>
          <div className="header_nav_name text-link">Home</div>
        </li>
      </Link>
      <Link to="/about" style={{ textDecoration: 'none' }}>
        <li>
          <div className="header_nav_name">About</div>
        </li>
      </Link>
      <Link to="/projects" style={{ textDecoration: 'none' }}>
        <li>
          <div className="header_nav_name">Projects</div>
        </li>
      </Link>
      <Link to="/blogs" style={{ textDecoration: 'none' }}>
        <li>
          <div className="header_nav_name">Blogs</div>
        </li>
      </Link>
      <Link to="/contactus" style={{ textDecoration: 'none' }}>
        <li>
          <div className="header_nav_name">Contact Us</div>
        </li>
      </Link>
    </UL>
  );
};

export default RightNav;
