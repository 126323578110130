const projectsdetail = [
  {
    "_id": {
      "$oid": "65b2852f4eadaf7a5c78e95c"
    },
    "id": 1,
    "projecttitle": "Amazon Clone",
    "projectdescription": "The Amazon Clone app is a replica of the popular e-commerce platform, Amazon. It offers users a seamless shopping experience with features closely mirroring those of the original site. Users can browse through a wide array of products, add items to their cart, and proceed to a secure checkout process.",
    "projectimage": "./Amazonclone.png",
    "url": "https://main--amazoncloneclient.netlify.app/",
    "githubUrl": "https://github.com/arjunsingh27/AmazonCloneClient",
    "datecreated": "Nov 17, 2023"
  },
  {
    "_id": {
      "$oid": "65b2852f4eadaf7a5c78e95d"
    },
    "id": 2,
    "projecttitle": "Weather Report",
    "projectdescription": "The Weather Report App delivers accurate and real-time weather information based on the city entered. Users can easily access up-to-date details",
    "projectimage": "./WeatherReport.png",
    "url": "https://weatherreport-w88p.onrender.com/",
    "githubUrl": "https://github.com/arjunsingh27/WeatherReport",
    "datecreated": "Jul 8, 2023"
  },
  {
    "_id": {
      "$oid": "65b2852f4eadaf7a5c78e95e"
    },
    "id": 7,
    "projecttitle": "Todolist",
    "projectdescription": "The Todo List App is a practical and efficient tool for organizing tasks and managing daily activities. Users can create and delete their to-do lists",
    "projectimage": "Todolist.png",
    "url": "https://to-do-list-g6l0.onrender.com/",
    "githubUrl": "https://github.com/arjunsingh27/AmazonCloneClient",
    "datecreated": "Jul 13, 2023"
  },
  {
    "_id": {
      "$oid": "65b2852f4eadaf7a5c78e95f"
    },
    "id": 4,
    "projecttitle": "Analog Clock",
    "projectdescription": "An Analog Clock App is a digital representation of the traditional clock with hour, minute, and second hands.",
    "projectimage": "https://png.pngtree.com/png-vector/20230131/ourmid/pngtree-analog-walll-watch-clipart-in-transparent-background-png-image_6575373.png",
    "url": "https://arjunsingh27.github.io/AnalogClock/",
    "githubUrl": "https://github.com/arjunsingh27/AnalogClock",
    "datecreated": "Jun 19, 2023"
  },
  {
    "_id": {
      "$oid": "65b2852f4eadaf7a5c78e960"
    },
    "id": 5,
    "projecttitle": "Note Wizard",
    "projectdescription": "I built a website in my Diploma to assist my classmates in accessing notes and timetables.",
    "projectimage": "https://github.com/arjunsingh27/NoteWizard/raw/main/NoteWizard.jpg",
    "url": "https://arjunsingh27.github.io/NoteWizard/",
    "githubUrl": "https://github.com/arjunsingh27/NoteWizard",
    "datecreated": "Mar 23, 2021"
  },
  {
    "_id": {
      "$oid": "65b2852f4eadaf7a5c78e961"
    },
    "id": 6,
    "projecttitle": "Color Picker",
    "projectdescription": "Color pickers often feature a diverse array of colors corresponding to website types. Users can visit sites to get the color codes that align with the unique color schemes adopted by websites.",
    "projectimage": "https://cdn-icons-png.flaticon.com/512/8377/8377243.png",
    "url": "https://arjunsingh27.github.io/ColorPicker/",
    "githubUrl": "https://github.com/arjunsingh27/ColorPicker",
    "datecreated": "Jan 04, 2024"
  },
  {
    "_id": {
      "$oid": "65b2852f4eadaf7a5c78e962"
    },
    "id": 3,
    "projecttitle": "Bareilly-Basket",
    "projectdescription": "Welcome to Bareilly Basket – your go-to e-commerce platform for cutting-edge Explore our collection products designed to enhance your modern lifestyle. We offer quality and innovation in every product. Shop securely, experience swift deliveries, and stay ahead with Bareilly Basket!",
    "projectimage": "https://builtin.com/sites/www.builtin.com/files/styles/og/public/2022-09/ecommerce.png",
    "url": "https://bareillybasket.netlify.app/",
    "githubUrl": "https://github.com/arjunsingh27/Bareilly-Basket",
    "datecreated": "Jan 09, 2024"
  },
  {
    "_id": {
      "$oid": "65c31efef0ab36188ba38849"
    },
    "id": 8,
    "projecttitle": "Photo Management",
    "projectdescription": "In the photo management app, users can effortlessly upload images along with corresponding titles and descriptions simultaneously. This feature allows for streamlined organization and easy sharing of memories, moments, and visual content.",
    "projectimage": "https://cdn-icons-png.flaticon.com/512/8377/8377243.png",
    "url": "https://photomanagementappusingcloudinary.netlify.app/",
    "githubUrl": "https://github.com/arjunsingh27/PhotoAppUsingCloudinary",
    "datecreated": "Feb 3, 2024"
  },
  {
    "_id": {
      "$oid": "65edde98f382f3ddd9147e63"
    },
    "id": 9,
    "projecttitle": "Chat Application",
    "projectdescription": "Real time Chat Application",
    "projectimage": "https://cdn.dribbble.com/users/1129235/screenshots/2628920/chat__2_.gif",
    "url": "https://chatapp-f2w1.onrender.com/",
    "githubUrl": "https://github.com/arjunsingh27/ChatApp",
    "datecreated": "March 2, 2024"
  }
];

export default projectsdetail;
