import React from 'react';

const CodeBlock = ({ codeheading, code }) => {
  // Check if code is empty or contains only whitespace
  if (!code || !code.trim()) return null;

  return (
    <div className="mt-6 p-4 bg-gray-100 rounded-lg">
      <h4 className="text-lg font-semibold mb-2">{codeheading}</h4>
      <pre className="bg-gray-800 text-white p-4 rounded-md h-auto whitespace-pre-wrap break-words">
        <code>{code}</code>
      </pre>
    </div>
  );
};

export default CodeBlock;
