import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faLaptopCode,
  faDatabase,
  faLaptopHouse,
  faLaptop,
} from "@fortawesome/free-solid-svg-icons";
import {
  faReact,
  faNodeJs,
  faJsSquare,
  faHtml5,
  faCss3Alt,
  faGitAlt,
} from "@fortawesome/free-brands-svg-icons";
 
import labout from "./assets/images/icons/logos/labout.png";
import collage from "./assets/images/icons/logos/collage.png";

function About() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      easing: 'fade-out', // Animation easing
      once: true, // Whether animation should happen only once
    });
  }, []);
  return (
    <div className="">
      <div data-aos="fade-down" className="shadow-md hover:shadow-blue-900 flex flex-col md:flex-row justify-between items-center p-5 sm:m-1 lg:m-20 lg:mt-20 lg:mb-40 m-5 rounded-lg">
        {/* Introductory Section */}
        <div className="about_text w-full md:w-1/2 text-left ">
          <h4 className=" text-3xl font-bold text-blue-800 mb-10 bg-gradient-to-r from-cyan-900 to-blue-300 bg-clip-text text-transparent">
            Hello! I'm Arjun Singh
          </h4>
          <p className="about_me text-lg leading-relaxed text-slate-600">
            A passionate <strong>Full Stack Developer</strong> with over 1 year
            of experience in crafting responsive, cutting-edge websites. My
            journey in web development is fueled by a deep interest in
            leveraging the latest tech stacks, from React and Node.js to AI/ML
            and Jenkins for CI/CD. Beyond building websites, I also thrive as a
            freelancer, offering comprehensive services in web development, web
            design, and more. Whether it's creating dynamic user interfaces or
            integrating intelligent systems, I’m dedicated to pushing the
            boundaries of technology to deliver exceptional digital experiences.
            <br />
            <br />
            <div className="bg-gradient-to-r from-cyan-300 to-blue-500 bg-clip-text text-transparent text-lg">
              Let’s build the future of the web together!&nbsp;&nbsp;&nbsp;
              <a
                className="hover:bg-gradient-to-r from-cyan-700 hover:bg-clip-text text-sm"
                href="../Arjun Singh.pdf"
                download
              >
                Download Cv
              </a>
            </div>
          </p>
        </div>

        {/* Icons Section */}
        <div className="flex justify-center m-auto ">
          <img
            className="h-auto max-w-full max-h-[200px] sm:max-h-[300px] md:max-h-[400px] object-contain mt-4"
            src={labout}
            alt="Logo"
          />
        </div>
      </div>

      {/* Timeline Section */}
      <h4 className=" text-center about_heading text-3xl font-bold text-blue-800 bg-gradient-to-r from-cyan-900 to-blue-300 bg-clip-text text-transparent">
              My Educational Journey
            </h4>
      <div data-aos="fade-down" className="bg-gradient-to-r from-teal-100 to-blue-300 m-5 mt-12 lg:m-20 shadow-lg hover:shadow-blue-900 pt-20 pb-10 rounded-lg hover:from-blue-300 hover:to-teal-100    

      ">
        
        <div className="flex flex-col md:flex-row">
          {/* Timeline Content */}
          
          <div className="w-full md:w-1/2 flex flex-col items-start pl-4">
            
            <div className="relative">
              <div className="absolute border-l-2 top-0 transform -translate-x-1/2"></div>
              <div className="flex flex-col items-start">
                <div className="timeline_item flex items-center mb-10 relative">
                  <div className="timeline_icon bg-green-500 text-white rounded-full p-3 z-10">
                    <FontAwesomeIcon icon={faLaptopCode} />
                  </div>
                  <div className="timeline_content ml-4">
                    <h5 className="text-xl font-semibold">
                      Bachelor in Computer Science
                    </h5>
                    <p className="text-gray-600">Invertis University (2022-2025)</p>
                  </div>
                </div>
                {/* College */}
                <div className="timeline_item flex items-center mb-10 relative">
                  <div className="timeline_icon bg-green-500 text-white rounded-full p-3 z-10">
                    <FontAwesomeIcon icon={faLaptopCode} />
                  </div>
                  <div className="timeline_content ml-4">
                    <h5 className="text-xl font-semibold">
                      Diploma in Computer Science
                    </h5>
                    <p className="text-gray-600">Invertis University (2019-2022)</p>
                  </div>
                </div>
                {/* Add more items here as needed */}
                {/* High School */}
                <div className="timeline_item flex items-center mb-10 relative">
                  <div className="timeline_icon bg-blue-500 text-white rounded-full p-3 z-10">
                    <FontAwesomeIcon icon={faCode} />
                  </div>
                  <div className="timeline_content ml-4">
                    <h5 className="text-xl font-semibold">High School</h5>
                    <p className="text-gray-600">
                      Bishop Conrad Senior Secondary School (2018-2019)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Collage Image */}
          <div className="w-full md:w-1/2 flex justify-center items-center">
            <img
              className="h-auto max-w-full max-h-[200px] sm:max-h-[300px] md:max-h-[400px] object-contain mt-4"
              src={collage}
              alt="Collage"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
