import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaAws } from "react-icons/fa";
import { SiSelenium } from "react-icons/si";
import { useHistory } from 'react-router-dom'; // Import useHistory for navigation
import Blog from '../blogs/Blog';

import {
  faHtml5, faCss3Alt, faJsSquare, faReact, faNodeJs,
  faGitAlt, faJenkins, faDocker, faGithub, faNpm, faLinux
} from '@fortawesome/free-brands-svg-icons';
import { faDatabase, faCodeBranch, faCloud } from '@fortawesome/free-solid-svg-icons';

const SkillComponent = () => {
  const history = useHistory(); // Initialize useHistory

  const handleIconClick = (id) => {
    history.push(`/Blog/${id}`); // Navigate to the specific vlog page
  };

  return (
    <div className="p-2 md:p-6">
      <h4 className="about_heading text-3xl font-bold text-blue-800 mb-10 bg-gradient-to-r from-cyan-900 to-blue-300 bg-clip-text text-transparent text-center">
        Technologies Used
      </h4>
      <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-6 justify-items-center">
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(1)}>
          <FontAwesomeIcon icon={faHtml5} className="md:text-6xl text-orange-600 mb-2" />
          <span className="text-xs md:text-lg">HTML</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(2)}>
          <FontAwesomeIcon icon={faCss3Alt} className="md:text-6xl text-blue-600 mb-2" />
          <span className="text-xs md:text-lg">CSS</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(3)}>
          <FontAwesomeIcon icon={faJsSquare} className="md:text-6xl text-yellow-500 mb-2" />
          <span className="text-xs md:text-lg">JavaScript</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(4)}>
          <FontAwesomeIcon icon={faReact} className="md:text-6xl text-cyan-500 mb-2" />
          <span className="text-xs md:text-lg">React</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(5)}>
          <FontAwesomeIcon icon={faCss3Alt} className="md:text-6xl text-teal-500 mb-2" />
          <span className="text-xs md:text-lg">Tailwind</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(6)}>
          <FontAwesomeIcon icon={faReact} className="md:text-6xl text-black mb-2" />
          <span className="text-xs md:text-lg">Next.js</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(7)}>
          <FontAwesomeIcon icon={faJsSquare} className="md:text-6xl text-blue-700 mb-2" />
          <span className="text-xs md:text-lg">TypeScript</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(8)}>
          <FontAwesomeIcon icon={faNodeJs} className="md:text-6xl text-green-600 mb-2" />
          <span className="text-xs md:text-lg">Node.js</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(9)}>
          <FontAwesomeIcon icon={faNpm} className="md:text-6xl text-green-600 mb-2" />
          <span className="text-xs md:text-lg">Npm</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(10)}>
          <FontAwesomeIcon icon={faJsSquare} className="md:text-6xl text-gray-500 mb-2" />
          <span className="text-xs md:text-lg">Express</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(11)}>
          <FontAwesomeIcon icon={faDatabase} className="md:text-6xl text-blue-800 mb-2" />
          <span className="text-xs md:text-lg">Postgres</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(12)}>
          <FontAwesomeIcon icon={faDatabase} className="md:text-6xl text-green-400 mb-2" />
          <span className="text-xs md:text-lg">MongoDB</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(13)}>
          <FontAwesomeIcon icon={faGithub} className="md:text-6xl text-orange-700 mb-2" />
          <span className="text-xs md:text-lg">GitHub</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(14)}>
          <FontAwesomeIcon icon={faCodeBranch} className="md:text-6xl text-purple-600 mb-2" />
          <span className="text-xs md:text-lg">CI/CD</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(15)}>
          <FontAwesomeIcon icon={faJenkins} className="md:text-6xl text-gray-600 mb-2" />
          <span className="text-xs md:text-lg">Jenkins</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(16)}>
          <FontAwesomeIcon icon={faCloud} className="md:text-6xl text-gray-600 mb-2" />
          <span className="text-xs md:text-lg">Cloudinary</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(17)}>
          <FontAwesomeIcon icon={faDocker} className="md:text-6xl text-blue-400 mb-2" />
          <span className="text-xs md:text-lg">Docker</span>
        </div>
        <div className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center" onClick={() => handleIconClick(18)}>
          <FontAwesomeIcon icon={faLinux} className="md:text-6xl text-blue-400 mb-2" />
          <span className="text-xs md:text-lg">Linux</span>
        </div>
        <div
        className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center cursor-pointer"
        onClick={() => handleIconClick(18)}
      >
        {/* <FontAwesomeIcon icon={faSelenium} className="md:text-6xl text-blue-400 mb-2" /> */}
        <SiSelenium className='md:text-6xl text-blue-400 mb-2'/>
        <span className="text-xs md:text-lg">Selenium</span>
      </div>
      <div
        className="flex flex-col transform transition-transform duration-300 hover:scale-110 items-center cursor-pointer"
        onClick={() => handleIconClick(19)}
      >
        {/* <FontAwesomeIcon icon={faLinux} className="md:text-6xl text-blue-400 mb-2" /> */}
        <FaAws className='md:text-6xl text-blue-400 mb-2'/>
        <span className="text-xs md:text-lg">AWS</span>
      </div>
      </div>
    </div>
  );
};

export default SkillComponent;
