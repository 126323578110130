import React from 'react';
import { Link } from 'react-router-dom';
 

const BlogsCard = (props) => {
  return (
      <div data-aos="fade-up">
    <div  className="transform transition-transform duration-300 hover:scale-105 bg-white/30 backdrop-blur-lg rounded-lg shadow-2xl hover:z-10 relative m-5 lg:ml-20 lg:mr-20">
      <Link  to={`/blog/${props.id}`}>
        <div  className="relative w-full mx-auto mb-10 overflow-hidden rounded-lg">
          {/* Card Content with Glass Effect */}
          <div className="relative bg-white bg-opacity-20 backdrop-blur-lg p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">{props.title}</h2>
            <p className="text-base text-gray-600">
              {props.introduction.slice(0, 120)}
              <Link className="text-indigo-600 hover:underline" to={`/blog/${props.id}`}>
                ...read more
              </Link>
            </p>
          </div>
        </div>
      </Link>
      </div>
    </div>
  );
};

export default BlogsCard;
