import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-in-out', // Smooth animation
    });
  }, []);

  return (
    <div className="relative py-12">
      {/* Background effects */}
      <div className="absolute inset-0 opacity-30 blur-2xl"></div>
      <div
        className="absolute w-40 right-1/2 lg:w-96 h-96 bg-gradient-to-r from-yellow-500 via-red-500 to-purple-500 rounded-full opacity-20 blur-3xl"
        style={{ pointerEvents: 'none', zIndex: -1 }}
      ></div>
      <div
        className="absolute top-1/2 right-1/4 w-10 lg:w-96 h-96 bg-gradient-to-r from-green-500 via-blue-500 to-teal-500 rounded-full opacity-20 blur-3xl"
        style={{ pointerEvents: 'none', zIndex: -1 }}
      ></div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-500 text-center mb-8" data-aos="fade-up">
          How I Can Help
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Service 1 */}
          <Link to="/contactus" className="transform transition-transform duration-300 hover:scale-105">
            <div
              className="p-6 bg-white/30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg hover:z-10"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <h3 className="text-xl font-semibold mb-4">Website Development</h3>
              <p className="text-gray-600">
                We build responsive and functional websites tailored to your needs.
              </p>
            </div>
          </Link>

          {/* Service 2 */}
          <Link to="/contactus" className="transform transition-transform duration-300 hover:scale-105">
            <div
              className="p-6 bg-white/30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg hover:z-10"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h3 className="text-xl font-semibold mb-4">Cloud Data Migration</h3>
              <p className="text-gray-600">
                We help shift your data to the cloud or migrate local data to cloud databases securely.
              </p>
            </div>
          </Link>

          {/* Service 3 */}
          <Link to="/contactus" className="transform transition-transform duration-300 hover:scale-105">
            <div
              className="p-6 bg-white/30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg hover:z-10"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h3 className="text-xl font-semibold mb-4">E-commerce Solutions</h3>
              <p className="text-gray-600">
                Develop and manage online stores that drive sales and growth.
              </p>
            </div>
          </Link>

          {/* Service 4 */}
          <Link to="/contactus" className="transform transition-transform duration-300 hover:scale-105">
            <div
              className="p-6 bg-white/30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg hover:z-10"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h3 className="text-xl font-semibold mb-4">SEO Optimization</h3>
              <p className="text-gray-600">
                Enhance your website’s visibility and ranking on search engines.
              </p>
            </div>
          </Link>

          {/* Service 5 */}
          <Link to="/contactus" className="transform transition-transform duration-300 hover:scale-105">
            <div
              className="p-6 bg-white/30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg hover:z-10"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <h3 className="text-xl font-semibold mb-4">UI/UX Design</h3>
              <p className="text-gray-600">
                Craft user-friendly interfaces that captivate and engage your audience.
              </p>
            </div>
          </Link>

          {/* Service 6 */}
          <Link to="/contactus" className="transform transition-transform duration-300 hover:scale-105">
            <div
              className="p-6 bg-white/30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg hover:z-10"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <h3 className="text-xl font-semibold mb-4">Content Management</h3>
              <p className="text-gray-600">
                Manage and create high-quality content for your platforms.
              </p>
            </div>
          </Link>

          {/* Service 7: Interactive Portfolio */}
          <Link to="/contactus" className="transform transition-transform duration-300 hover:scale-105">
            <div
              className="p-6 bg-white/30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg hover:z-10"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <h3 className="text-xl font-semibold mb-4">Interactive Portfolio</h3>
              <p className="text-gray-600">
                Create visually engaging and interactive portfolios to showcase your work.
              </p>
            </div>
          </Link>

          {/* Service 8: College or School Website */}
          <Link to="/contactus" className="transform transition-transform duration-300 hover:scale-105">
            <div
              className="p-6 bg-white/30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg hover:z-10"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <h3 className="text-xl font-semibold mb-4">College or School Website</h3>
              <p className="text-gray-600">
                Design and develop websites for educational institutions.
              </p>
            </div>
          </Link>

          {/* Service 9: Taking Local Business Online */}
          <Link to="/contactus" className="transform transition-transform duration-300 hover:scale-105">
            <div
              className="p-6 bg-white/30 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg hover:z-10"
              data-aos="fade-up"
              data-aos-delay="800"
            >
              <h3 className="text-xl font-semibold mb-4">Taking Local Business Online</h3>
              <p className="text-gray-600">
                We help local businesses establish an online presence to reach a wider audience.
              </p>
            </div>
          </Link>

        </div>
      </div>
    </div>
  );
}

export default Services;
